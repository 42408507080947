
.cantainer {
  position: absolute;
  left: 50%;
  margin-left: -50px;
  top: 50%;
  margin-top: -40px;
}

.mug {
  position: absolute;
  width: 92px;
  height: 80px;
  background: repeating-linear-gradient(70deg, #f4bbac, #f4bbac 10px, #f1dfcb 10px, #f1dfcb 20px);
  border-radius: 20px 20px 50px 50px;
  border: 4px solid #f4bbac;
}

.mug:after {
  content: "";
  position: absolute;
  width: 30px;
  height: 25px;
  border: 10px solid #f4bbac;
  border-radius: 100%;
  right: -28px;
  top: 10px;
  z-index: -1;
}

.cat {
  width: 90px;
  height: 55px;
  background-color: #999;
  position: absolute;
  border-radius: 250px 250px 120px 120px;
  left: 5px;
  top: -30px;
  box-shadow: 0px 6px 0px 1px rgba(244, 187, 172, 1);
  z-index: 2;
}

.cat:after,
.cat:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 18px solid transparent;
  border-right: 18px solid transparent;
  border-bottom: 25px solid #999;
  top: -10px;
}

.cat:after {
  transform: rotate(-35deg);
}

.cat:before {
  transform: rotate(35deg);
  right: 0;
}

.eyes li {
  position: absolute;
  list-style: none;
  float: left;
  width: 6px;
  height: 6px;
  background-color: #fff;
  border: 2px solid black;
  border-radius: 100%;
}

.eyes li:first-child {
  right: 25px;
}

.eyes li:nth-child(2) {
  left: 25px;
}

.eyes li:after {
  content: "";
  position: absolute;
  height: 4px;
  width: 2px;
  background-color: black;
  top: -4px;
  left: -2px;
  transform: rotate(120deg);
}

.mouth {
  position: absolute;
  left: 40px;
  top: 28px;
}

.mouth:after,
.mouth:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 8px;
  background-color: black;
  transform: rotate(-55deg);
}

.mouth:after {
  transform: rotate(55deg);
  right: -8px;
}

.shadow {
  width: 90px;
  height: 30px;
  border-radius: 100%;
  background-color: #a9a7a5;
  position: absolute;
  top: 70px;
  left: 6px;
  opacity: 0.5;
}

.contact-form {
    width: 100%;
    margin-top: 20px;
  
    ul {
      padding: 0;
      margin: 0;
  
      li {
        padding: 0;
        margin: 0;
        list-style: none;
        margin-bottom: 10px;
        opacity: 0;
        overflow: hidden;
        display: block;
        clear: both;
        position: relative;
        animation: fadeInUp 2s 2s;
        animation-fill-mode: forwards;
      }
  
      li.half {
        width: 49%;
        margin-left: 2%;
        float: left;
        clear: none;
  
        &:first-child {
          margin-left: 0;
        }
      }
    }
  
    input[type='text'],
    input[type='email'] {
      width: 100%;
      border: 0;
      background: #115173;
      height: 50px;
      font-size: 16px;
      color: #fff;
      padding: 0 20px;
      box-sizing: border-box;
    }
  
    textarea {
      width: 100%;
      border: 0;
      background: #115173;
      height: 50px;
      font-size: 16px;
      color: #fff;
      padding: 20px;
      box-sizing: border-box;
      min-height: 150px;
    }
  
    .flat-button {
      color: aqua;
      font-size: 11px;
      letter-spacing: 3px;
      text-decoration: none;
      padding: 8px 10px;
      border: 1px solid aqua;
      float: left;
      border-radius: 4px;
      background: 0 0;
      text-transform: uppercase;
      float: right;
      text-align: center;
      margin-right: 10px;
      
    &:hover {
      background: #115173;
      color: #333;
      animation: fadeInAnimation 1s 1.8s forwards;}
    }
  }
  
  .map-wrap {
    background: rgba(8, 253, 216, 0.1);
    float: right;
    width: 53%;
    height: 115%;
    margin-top: -3%;
  }
  
  .leaflet-container {
    position: relative;
    width: 100%;
    height: 100%;
    opacity: 0;
    animation: backInRight 1s 1.2s;
    animation-fill-mode: forwards;
  }
  
  .info-map {
    position: absolute;
    background: #000;
    top: 50px;
    right: 30%;
    z-index: 999999;
    width: 267px;
    padding: 20px;
    color: #fff;
    font-family: 'Helvetica';
    font-size: 17px;
    font-weight: 300;
    opacity: 0;
    animation: fadeIn 1s 1.5s;
    animation-fill-mode: forwards;
  
    span {
      font-size: 16px;
  
      span {
        color: #ffd700;
      }
    }
  }
  
  @media screen and (max-width: 1200px) {
    .map-wrap {
      float: none;
      margin: 0;
      width: 100%;
      height: 400px;
    }
    
    .info-map {
      display: none;
    }
  }
.logo-container {// this is only for s svg
    z-index: 0;
    width: 400px;
    height: 609px;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 15%;
    bottom: 0;
    left: auto;
    margin: auto;
  
    svg {
      width: 100%;
      height: auto;
      bottom: 0;
      transform: rotateZ(30deg) !important;
      //yeh s ko thore sa rotate kr raha hai
    }
  
    .solid-logo {
      position: absolute;
      top: auto;
      right: auto;
      bottom: auto;
      left: 0;
      margin: auto;
      width: 100%;
      opacity: 0;
      transform: rotateZ(30deg) !important;
      z-index: 1;
      // this is for image my name
    }
  }
  
  .svg-container {
    stroke: aqua;
    stroke-width: 10;
    // is ki waja se mera text phly aqua hota slowly slowly phir logo a jata hai 
    // yeh effect mujhe sb se acha lga
  }
  
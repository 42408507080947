.page { //this is for home page
    width: 100%;
    height: 100%;
    position: absolute;
  }
  
  .top-tags {
    bottom: auto;
    top: 35px;
  }
  
  .tags { // home page pr james likha howa hai yeh us ke liye hai
    color: #05c72f;
    opacity: 0.8;// yeh us ki visibility hai
    position: absolute;
    bottom: 0;
    left: 120px;
    font-size: 20px;
    font-family: 'La Belle Aurore', cursive;
  }
  
  .bottom-tag-html {
    margin-left: -20px;
  }
  
  .container { //
    width: 100%;
    will-change: contents;
    height: 90%;
    min-height: 566px;
    position: absolute;
    opacity: 0;
    top: 5%;
    margin: 0 auto;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
  }
  

  // main ne contact page about aur portfolio ko aik sath properties di hain  yahi to fida hai sass ka 
  
  .container.about-page{
    
      h1 {
        font-size: 53px;
        font-family: 'Coolvetica';
        color: aqua;
        font-weight: 400;
        margin-top: 0;
        position: relative;
        margin-bottom: 40px;
        left: -10px;
  
        &:before {
          content: ' i have mention my contact so you can also leave a email';
          font-family: 'La Belle Aurore', cursive;
          color: #ffd700;
          font-size: 18px;
          position: absolute;
          margin-top: -10px;
          left: -10px;
          opacity: 0.6;
          line-height: 18px;
        }
  
        &:after {
          content: 'these are my skills ';
          font-family: 'La Belle Aurore', cursive;// that my text is showing teda meda
          color: #05c72f;
          font-size: 25px;
          line-height: 18px;
          position: absolute;
          left: -30px;
          bottom: -20px;
          margin-left: 20px;
          opacity: 0.6;
        }
      }
  
      p {
        font-size: 13px;
        color: #fff;
        font-family: sans-serif;
        font-weight: 300;
        max-width: fit-content;
        animation: pulse 1s;
        &:nth-of-type(1) {
          animation-delay: 1.1s;
        }
        &:nth-of-type(2) {
          animation-delay: 1.5s;
        }
        &:nth-of-type(3) {
          animation-delay: 1.8s;
        }
        &:nth-of-type(4) {
          animation-delay: 1.9s;
        }
      }
  // add more text for portfolio
    .text-zone {
      position: absolute;
      left: 10%;
      top: 50%;
      transform: translateY(-50%);
      width: 35%;
      vertical-align: middle;
      display: table-cell;
      max-height: 90%;
    }
  
    .text-animate-hover {
      &:hover {
        color: #fff;
      }
    }
  }
  .container.portfolio-page{
    
    h1 {
      font-size: 53px;
      font-family: 'Coolvetica';
      color: aqua;
      font-weight: 400;
      margin-top: 0;
      position: relative;
      margin-bottom: 40px;
      left: -10px;

      &:before {
        font-family: 'La Belle Aurore', cursive;
        color: #ffd700;
        font-size: 18px;
        position: absolute;
        margin-top: -10px;
        left: -10px;
        opacity: 0.6;
        line-height: 18px;
      }

      &:after {
        content: 'This is my Education  and carrer ';
        font-family: 'La Belle Aurore', cursive;// that my text is showing teda meda
        color: #05c72f;
        font-size: 25px;
        line-height: 18px;
        position: absolute;
        left: -30px;
        bottom: -20px;
        margin-left: 20px;
        opacity: 0.6;
      }
    }

    p {
      font-size: 13px;
      color: #fff;
      font-family: sans-serif;
      font-weight: 300;
      max-width: fit-content;
      animation: pulse 1s;
      &:nth-of-type(1) {
        animation-delay: 1.1s;
      }
      &:nth-of-type(2) {
        animation-delay: 1.5s;
      }
      &:nth-of-type(3) {
        animation-delay: 1.8s;
      }
      &:nth-of-type(4) {
        animation-delay: 1.9s;
      }
    }
// add more text for portfolio
  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 35%;
    vertical-align: middle;
    display: table-cell;
    max-height: 90%;
  }

  .text-animate-hover {
    &:hover {
      color: #fff;
    }
  }
}
  .container.contact-page{
    
    h1 {
      font-size: 53px;
      font-family: 'Coolvetica';
      color: aqua;
      font-weight: 400;
      margin-top: 0;
      position: relative;
      margin-bottom: 40px;
      left: -10px;

      &:before {
        content: ' i have mention my contact so you can also leave a email';
        font-family: 'La Belle Aurore', cursive;
        color: #ffd700;
        font-size: 18px;
        position: absolute;
        margin-top: -10px;
        left: -10px;
        opacity: 0.6;
        line-height: 18px;
      }

      &:after {
        content: 'I will youe Message if you send me sms i could be very greatful ';
        font-family: 'La Belle Aurore', cursive;// that my text is showing teda meda
        color: #05c72f;
        font-size: 25px;
        line-height: 18px;
        position: absolute;
        left: -30px;
        bottom: -20px;
        margin-left: 20px;
        opacity: 0.6;
      }
    }

    p {
      font-size: 13px;
      color: #fff;
      font-family: sans-serif;
      font-weight: 300;
      max-width: fit-content;
      animation: pulse 1s;
      &:nth-of-type(1) {
        animation-delay: 1.1s;
      }
      &:nth-of-type(2) {
        animation-delay: 1.5s;
      }
      &:nth-of-type(3) {
        animation-delay: 1.8s;
      }
      &:nth-of-type(4) {
        animation-delay: 1.9s;
      }
    }
// add more text for portfolio
  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 35%;
    vertical-align: middle;
    display: table-cell;
    max-height: 90%;
  }

  .text-animate-hover {
    &:hover {
      color: #fff;
    }
  }
}
  
  @media screen and (max-width: 1200px) {
    .page {
      position: initial;
    }
  
    .container {
      position: initial;
      height: auto;
      min-height: auto;
  
      &.contact-page,
      &.about-page,
      &.portfolio-page {
        .text-zone {
          position: initial;
          transform: none;
          width: 100%;
          display: block;
          padding: 20px;
          box-sizing: border-box;
        }
      }
  
      &.portfolio-page {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
  
        h1.page-title {
          margin-left: 20px;
          margin-top: 20px;
        }
  
        .image-box {
          height: 200px;
          max-width: calc(50% - 10px);
        }
      }
    }
  
    .stage-cube-cont {
      position: initial;
      width: 100%;
      height: 0%;
      overflow: visible;
    }
  }